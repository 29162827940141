@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* remove the error overlay */
body > iframe { display: none }

p, span {
  font-size: 12px;
  color: white;
}

html {
  height: 100%;
  background-color: black;
  width:100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width:100%;
}

#root {
  height: 100%;
  width:100%;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 1,
  'wght' 300,
  'GRAD' 0,
  'opsz' 48
}

::-webkit-scrollbar {
  background-color: black;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: white; /* Gray thumb color */
}

::-moz-scrollbar {
  background-color: black;
  width: 5px;
}

::-moz-scrollbar-thumb {
  background-color: white; /* Gray thumb color */
}

::-ms-scrollbar {
  background-color: black;
  width: 5px;
}

::-ms-scrollbar-thumb {
  background-color: white; /* Gray thumb color */
}

/* This is to handle autofill styling */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}